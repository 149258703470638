import React from 'react';
import { ProductTraceI, ProductTraceReportTypeT } from '../../interfaces/productTrace';
import CheckCircleIcon from '../../svgs/CheckCircleIcon';
import Text from '../Text';
import SuccessSubmittedItem from '../shared/SuccessSubmittedItem';
import MarkReadIcon from '../../svgs/MarkReadIcon';

type Props = {
  reportType?: ProductTraceReportTypeT;
  createdData?: ProductTraceI;
};

const ProductTraceSuccessModal: React.FC<Props> = ({ reportType, createdData }) => {
  return (
    <div className="tw-text-center tw-py-4 tw-px-4">
      <div className="tw-text-center">
        <div className="tw-w-auto">
          {reportType === 'trace' ? (
            <CheckCircleIcon width={48} height={48} color="#0A7A08" />
          ) : (
            <MarkReadIcon color="#0A7A08" width={32} height={32} />
          )}
        </div>
        <Text variant="h3" className="tw-mt-2 tw-mb-3">
          {reportType === 'trace'
            ? 'You have successfully submitted!'
            : 'You have successfully created the Product Trace Report and sent emails to notify involved Partners!'}
        </Text>
      </div>
      <div className="notice tw-mb-8 tw-text-grey-700">
        <Text variant="bodyMd" className="!tw-block">
          Few actions are available for this entry.
        </Text>
        <Text variant="bodyMd" className="!tw-block">
          You can choose the options below
        </Text>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-3">
        <SuccessSubmittedItem
          url={`/product-traces/${createdData?.code}`}
          title="View the Product Trace Report"
          description={['View the report you just created.']}
        />
      </div>
    </div>
  );
};

export default ProductTraceSuccessModal;
