import React from 'react';
import DetailsView from '../DetailsView';
import ProductTraceCurrentVolumeChart from './CurrentVolumeChart';
import { ProductTraceI, ProductTraceReportTypeT } from '../../interfaces/productTrace';
import ProductTraceVolumeHeldChart from './VolumeHeldChart';
import ProductTraceEmailsSentChart from './EmailsSentChart';

type Props = {
  data?: ProductTraceI['situationData'];
  emailsSent?: ProductTraceI['emailInfo'];
  reportType?: ProductTraceReportTypeT;
};

const ProductTraceSituationReport: React.FC<Props> = ({ data, emailsSent, reportType = 'trace' }) => {
  return (
    <DetailsView title="Situation Report (Live)">
      <div className="tw-gap-6 tw-grid tw-grid-cols-2 sm:tw-grid-cols-1">
        <ProductTraceCurrentVolumeChart data={data?.currentVolume} />
        <ProductTraceVolumeHeldChart data={data?.volumeHeldSiteTypes} />
      </div>
      {reportType !== 'trace' && <ProductTraceEmailsSentChart data={emailsSent} />}
    </DetailsView>
  );
};

export default ProductTraceSituationReport;
