import { Badge, Button, message, Popover, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import Icon from './Icon';
import Text from './Text';
import { QueryParams } from '../interfaces';
import { useQuery } from 'react-query';
import { doGetNotificationDetails, doGetNotificationsList } from '../apis/notifications';
import Avatar from './User/Avatar';
import { displayTimeAgos } from '../utils/global';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMutation, useQueryClient } from 'react-query';
import { doUpdateNotification } from '../apis/notifications';
import EmptyData from './EmptyData';
import { NotificationI } from '../interfaces/notification';
import { useNavigate } from 'react-router-dom';

const ITEM_LIMIT = 4;

const Notification: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useState<QueryParams>({ page: 1, limit: ITEM_LIMIT });
  const { data: { notifications = [], totalUnread = 0, total = 0 } = {} } = useQuery(
    ['notifications', 'list', queryParams],
    () => doGetNotificationsList(queryParams),
    {
      select({ data: { notifications, total, totalUnread } }) {
        return { notifications, total, totalUnread };
      },
    },
  );

  const [items, setItems] = useState<NotificationI[]>([]);
  useEffect(() => {
    if (notifications.length > 0) {
      setItems(notifications);
    }
    if (notifications.length >= total) {
      setHasMore(false);
    } else setHasMore(true);
  }, [notifications]);

  const renderNotificationContent = (item: NotificationI) => {
    return (
      <>
        {!item.isRead && (
          <span className="tw-w-2.5 tw-h-2.5 tw-bg-error-500 tw-absolute tw-right-1 tw-top-1 tw-rounded-full" />
        )}
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <Text variant="labelMd" className="tw-text-grey-700">
            Fault Report
          </Text>
          <Text variant="bodyMd" className="tw-text-grey-700">
            {displayTimeAgos(item.createdAt)}
          </Text>
        </div>
        <div className="tw-text-left">
          <div className="tw-flex tw-items-center tw-flex-wrap">
            <Avatar
              className={`!tw-w-[32px] !tw-h-[32px] !tw-max-w-[32px] !tw-max-h-[32px] tw-rounded-[100px] tw-mr-2`}
              id={item?.relations?.createdBy.partner.id}
            />
            <Text variant="bodyLgB">{item?.relations?.createdBy.partner.displayName}</Text>
            <Text variant="bodyLg">
              &nbsp; has {item.type === 'FaultReportCreate' ? 'created' : 'resolved'} Fault Report
            </Text>
          </div>
          <Text variant="bodyLg" className="tw-ml-10">
            <b>{item.relations.faultReport.code}</b> for hardware <b>{item.relations.hardware.serialCode}</b>
          </Text>
        </div>
      </>
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      queryClient.invalidateQueries(['notifications', 'list', queryParams]);
    }, 30000);

    return () => clearInterval(interval);
  }, [queryParams, queryClient]);

  const fetchMoreData = () => {
    if (notifications && notifications.length >= total) {
      setHasMore(false);
      return;
    }

    setQueryParams((prevParams) => ({
      ...prevParams,
      limit: prevParams.limit + ITEM_LIMIT,
    }));
  };

  const { mutate: updateIsReadMutate } = useMutation(doUpdateNotification, {
    onSuccess: async () => {
      queryClient.invalidateQueries();
    },
  });

  const handleMarkAllRead = () => {
    updateIsReadMutate();
  };

  const handleMarkItemRead = async (item: NotificationI) => {
    if (item.isRead) {
      navigate(`/fault-reports/${item.relations.faultReport.code}`);
    } else {
      try {
        const response = await doGetNotificationDetails(item.uuid);
        if (response) {
          navigate(`/fault-reports/${item.relations.faultReport.code}`);
          queryClient.invalidateQueries();
        }
      } catch (error) {
        message.error('Failed to mark as read');
      }
    }
  };

  return (
    <Popover
      placement="bottom"
      arrow={false}
      trigger={'click'}
      onOpenChange={(visible) => setVisible(visible)}
      rootClassName="tw-w-full tw-max-w-[468px] tw-rounded-lg"
      overlayInnerStyle={{ padding: 0, borderRadius: 8 }}
      align={{ offset: [-24, -12] }}
      title={
        <div className="tw-flex tw-justify-between tw-bg-white tw-p-4 tw-mb-0">
          <Text variant="h3">Notifications</Text>
          <Button className="tw-border-primary" onClick={handleMarkAllRead}>
            <Icon name="icon-remove_red_eye" size={16} />
            <Text variant="buttonSm">Mark all as read</Text>
          </Button>
        </div>
      }
      content={
        <div
          id="scrollableDiv"
          className="tw-bg-grey-50 tw-p-4 tw-border-primary !tw-border-b-0 !tw-border-l-0 !tw-border-r-0 tw-max-h-[50dvh] tw-overflow-auto"
        >
          {items.length > 0 ? (
            <InfiniteScroll
              dataLength={items?.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<Spin style={{ paddingTop: 10, width: '100%' }} />}
              scrollableTarget="scrollableDiv"
              endMessage={
                <p style={{ textAlign: 'center', paddingTop: 15 }}>
                  <b>You have seen it all</b>
                </p>
              }
            >
              {items.map((item) => (
                <button
                  onClick={() => handleMarkItemRead(item)}
                  key={item.uuid}
                  className={'tw-p-4 tw-bg-white tw-rounded-lg tw-mb-4 last:tw-mb-0 tw-relative tw-border-none'}
                  style={{ background: !item.isRead ? '#FFFFFF' : 'none' }}
                >
                  {renderNotificationContent(item)}
                </button>
              ))}
            </InfiniteScroll>
          ) : (
            <EmptyData message="No Notifications" />
          )}
        </div>
      }
    >
      <Badge count={totalUnread ?? 0} overflowCount={99} color="#B13939" offset={[-2, 5]} size="small">
        <span style={{ color: visible ? 'green' : '' }}>
          {visible ? <Icon name="icon-notifications" /> : <Icon name="icon-notifications_none" />}
        </span>
      </Badge>
    </Popover>
  );
};

export default Notification;
