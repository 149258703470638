export const ProductTraceTotalIntroducedTooltip =
  '<b>Total Introduced</b> refers to the volume of product from the traced Lot Number, added via fills from ecoPLANT(s)';
export const ProductTraceInCirculationTooltip =
  '<b>In Circulation</b> refers to the volume of product from the traced Lot Number, currently held in ecoTOTE(s)';
export const ProductTraceConsumedTooltip =
  '<b>Consumed</b> refers to the volume of product from the traced Lot Number that has been consumed from ecoTOTE(s) and SmartPour(s)';

export const VolumeHeldInCirculationTooltip =
  '<b>In Circulation</b> refers to the volume of product from the traced Lot Number currently held in ecoTOTE(s), located at all Site Types';
export const VolumeHeldConsumeTooltip =
  '<b>Consume</b> refers to the volume of product from the traced Lot Number currently held in ecoTOTE(s), located at Consume Sites';
export const VolumeHeldFillingTooltip =
  '<b>Filling</b> refers to the volume of product from the traced Lot Number currently held in ecoTOTE(s), located at Filling Sites';
export const VolumeHeldStorageTooltip =
  '<b>Storage</b> refers to the volume of product from the traced Lot Number currently held in ecoTOTE(s), located at Storage Sites';

export const TotalEmailsSentTooltip =
  "<b>Total Emails Sent</b> refers to the total number of notification emails of all Trace Report’s statuses that have been sent to all affected partners's recipients";
export const EmailsSentByStatusTooltip =
  '<b>Email Sent by Trace Report Status</b> refers to a breakdown of sent emails of all Trace Report’s statuses';
