import { BaseResponse, GetListResponse } from '../interfaces';
import { handleFilterParams } from '../utils/global';
import { NotificationCollectionI, NotificationI, NotificationSearchParamsI } from '../interfaces/notification';
import { useFetch } from './reactQuery';
import { api } from './api';

export const useGetNotificationsList = () => useFetch<GetListResponse<NotificationI>>('notifications');

export const useGetNotificationDetails = (uuid: string) => useFetch<NotificationI>(`notifications/${uuid}`);

export const doGetNotificationsList = async (params: NotificationSearchParamsI) => {
  const newParams = handleFilterParams(params);
  const { data: response } = await api.get<BaseResponse<NotificationCollectionI>>(`notifications?${newParams}`);
  return response;
};

export const doGetNotificationDetails = async (uuid: string) => {
  const { data: response } = await api.get<BaseResponse<NotificationI>>(`notifications/${uuid}`);
  return response.data;
};

export const doUpdateNotification = async () => {
  const { data: response } = await api.post<{ status: number; data: any }>(`notifications/read-all`, {});
  return response.data;
};
