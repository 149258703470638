import { AttachmentI } from './../interfaces/attachments';
import * as qs from 'qs';
import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  APP_VERSION,
  DATETIME_FORMAT,
  DATE_FORMAT_FRONTEND,
  RoleCheckboxOptions,
  TIME_FORMAT_FRONTEND,
} from '../constants';
import countries from 'react-select-country-list';
import { CheckboxOptionI, DateRangeT } from '../interfaces';
import { PermissionsI } from '../interfaces/module';
import { ProductCategoryI } from '../interfaces/productCategory';
import { allAttachmentTypes, logoType } from './image';
import { ConfigurationUnits } from '../constants/configuration';
import { SiteI } from '../interfaces/site';
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

export const humanizeText = (text: string) => {
  return text?.replace(/_/g, ' ');
};

export const handleFilterParams = (params: any) => {
  return qs.stringify(params, { encode: false, skipNulls: true, arrayFormat: 'comma' });
};

export const convertDayJSToISOString = (dayjsString: Dayjs) => {
  return dayjs(dayjsString);
};

export const convertDayJSToApi = (dayjsString: Dayjs) => {
  return dayjs(dayjsString).format('DD-MM-YYYY');
};

export const DateFormat = (date: any, format?: string) => {
  if (date === null) {
    return;
  }

  return dayjs(date).format(format ?? DATE_FORMAT_FRONTEND);
};

export const convertStringtoDate = (dateString: string | Date) => {
  const date = dayjs(dateString, 'DD-MM-YYYY');
  return date.isValid() ? date.format('DD MMM') : null;
};

export const TimeFormat = (date: any) => {
  if (date === null) {
    return;
  }

  return dayjs(date).format(TIME_FORMAT_FRONTEND);
};

export const DateTimeFormat = (datetime: any, format?: string) => {
  if (datetime === null) return;
  return dayjs(datetime).format(format ?? DATETIME_FORMAT);
};

export const convertDateTimeToNumber = (datetime: Dayjs) => {
  return datetime ? Number(dayjs(datetime).valueOf()) : null;
};

export const handleDisplayFilterFields = (searchString: string) => {
  const newSearch = searchString.replace('?', '');
  const oldSearch: any = qs.parse(newSearch);
  return oldSearch?.filter || undefined;
};

export const getCountryFromCode = (code: string) => {
  if (!code) return '';
  return countries().getLabel(code) ?? '';
};

export const handleDisplayAddress = (record: any) => {
  if (!record) return '';
  return `${record?.addressLine1} 
  ${record?.addressLine2 ? record.addressLine2 : ''} 
  ${record?.addressLine3 ? record.addressLine3 : ''}, 
  ${record?.region ? record.region : ''}, 
  ${record?.state ? record.state : ''}, 
  ${record?.country ? getCountryFromCode(record.country) : ''}, 
  ${record?.postalCode}`;
};

export const convertToApiFile = (file: any): AttachmentI => {
  delete file?.url;
  delete file?.thumbUrl;
  delete file?.uid;

  return {
    fileName: file.name,
    contentType: file.type,
    categoryName: file.categoryName ?? '',
    url: file.url,
    data: file.data,
  };
};

export const getModulePermissions = (modulePermission: PermissionsI[]) => {
  if (!modulePermission || (modulePermission && Object.keys(modulePermission).length === 0)) return [];
  return RoleCheckboxOptions.map((item: CheckboxOptionI) => {
    const value = item?.value;
    if (!modulePermission?.includes(value)) {
      return {
        ...item,
        disabled: true,
      };
    }
    return item;
  });
};

export const getUploadFileType = {
  certificate: allAttachmentTypes,
  logo: logoType,
  fault: allAttachmentTypes,
  brandPlate: allAttachmentTypes,
  others: allAttachmentTypes,
};

export const hasDuplicateBottleCodeValues = (array: { value: string }[]) => {
  let uniqueValues = new Set();

  for (let obj of array) {
    if (uniqueValues.has(obj.value)) {
      return true;
    } else {
      uniqueValues.add(obj.value);
    }
  }

  return false;
};

export const getProductCategory = (category: ProductCategoryI | undefined) => {
  if (!category) return '';
  if (category?.type === 'Beverage Sub-Classification') {
    return category.parent?.name;
  } else {
    return category?.name;
  }
};

export const AmericanOzToMili = (amount: number) => Math.round(amount * 29.5735);
export const BritishOzToMili = (amount: number) => Math.round(amount * 28.4131);
export const MiliToAmericanOz: Record<number, number> = {
  15: 0.5,
  22: 0.75,
  30: 1.0,
  37: 1.25,
  44: 1.5,
  52: 1.75,
  59: 2.0,
  67: 2.25,
  74: 2.5,
  81: 2.75,
  89: 3.0,
  96: 3.25,
};
export const MiliToBritishOz: Record<number, number> = {
  14: 0.5,
  21: 0.75,
  28: 1.0,
  36: 1.25,
  43: 1.5,
  50: 1.75,
  57: 2.0,
  64: 2.25,
  71: 2.5,
  78: 2.75,
  85: 3.0,
  92: 3.25,
};

export const GetConfigUnitText = (value: string) => {
  return ConfigurationUnits.find((item: { value: string; name: string }) => item.value === value)?.name;
};

export const GetConfigUnit = (value: string) => {
  if (value === 'ozUS') return 'Fl oz';
  if (value === 'ozUK') return 'oz';
  return 'mL';
};

export const ConvertMLToUnit = (value: number, unit: string): any => {
  if (unit === 'ozUS') return MiliToAmericanOz[value];
  if (unit === 'ozUK') return MiliToBritishOz[value];
  return value ?? 10;
};

export const GetPlatformInfo = (agent: string) => {
  let browserInfo;
  if (agent.includes('Chrome')) {
    browserInfo = {
      platform: 'Google Chrome',
      appVersion: APP_VERSION,
    };
  } else if (agent.includes('Firefox')) {
    browserInfo = {
      platform: 'Mozilla Firefox',
      appVersion: APP_VERSION,
    };
  } else if (agent.includes('Safari') && !agent.includes('Chrome')) {
    browserInfo = {
      platform: 'Safari',
      appVersion: APP_VERSION,
    };
  } else if (agent.includes('Edg')) {
    browserInfo = {
      platform: 'Microsoft Edge',
      appVersion: APP_VERSION,
    };
  } else {
    browserInfo = 'Unknown browser';
  }
  return browserInfo;
};

export const findDateRangeOption = (
  startDate: string,
  endDate: string,
  dateRangeOptions: DateRangeT[],
): DateRangeT | undefined => {
  const start = dayjs(startDate, 'DD-MM-YYYY');
  const end = dayjs(endDate, 'DD-MM-YYYY');

  const matchingOption = dateRangeOptions.find(
    (option) => dayjs(option.period.since).isSame(start, 'day') && dayjs(option.period.until).isSame(end, 'day'),
  );

  return matchingOption ?? dateRangeOptions[-1];
};

const gcd = (a: number, b: number): string | number => {
  return b === 0 ? a : gcd(b, a % b);
};

export function simplifyFraction(numerator: number, denominator: number) {
  const divisor: any = gcd(numerator, denominator);
  return numerator / divisor + '/' + denominator / divisor;
}

export const getAddress = (site: SiteI): string => {
  return `${site.addressLine1}, 
  ${site.addressLine2 !== null ? site.addressLine2 + ', ' : ''}
  ${site.addressLine3 !== null ? site.addressLine3 + ', ' : ''}
  ${getCountryFromCode(site.country)}, 
  ${site.postalCode}`;
};

export const convertLiterToMl = (value?: number): string => {
  if (value === undefined || value === null) return (0).toFixed(2);
  return (value / 1000).toFixed(2);
};

export const displayTimeAgos = (createdAt: string) => {
  const date = dayjs(createdAt);
  const now = dayjs();
  const diffInDays = now.diff(date, 'day');

  if (diffInDays > 31) {
    return date.format('MMM DD, YYYY');
  }
  return date.fromNow();
};
