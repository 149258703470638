import React, { ReactNode } from 'react';
import { ScanCode } from '../shared/Scanner';
import Text from '../Text';
import usePageTimeTracker from '../../hooks/usePageTimeTracker';
import Icon from '../Icon';

type Props = {
  stopDecodingScanner: boolean;
  scanNoticeMessage: string | ReactNode;
  pageName?: string;
  title?: string;
  handleScanOrManualSubmit: (_: any) => void;
  setStopDecodingScanner: (_: any) => void;
};

const FillingScanQRCode: React.FC<Props> = ({
  scanNoticeMessage,
  stopDecodingScanner,
  pageName,
  title = 'Scan by QR/Bar Code',
  handleScanOrManualSubmit,
  setStopDecodingScanner,
}) => {
  usePageTimeTracker(pageName ?? '');

  return (
    <div className="component-hardware-filling-scan-qrcode">
      <Text variant="h2" className="tw-w-full tw-text-center">
        {title}
      </Text>
      <p className="tw-flex tw-justify-center tw-gap-3 tw-max-w-[500px] tw-my-4 tw-mx-auto">
        <Icon name="icon-info" className="tw-min-w-4 tw-w-4" />
        <span>{scanNoticeMessage}</span>
      </p>
      <ScanCode
        stopDecodingScanner={stopDecodingScanner}
        setStopDecodingScanner={setStopDecodingScanner}
        handleScanOrManualSubmit={handleScanOrManualSubmit}
      />
    </div>
  );
};

export default FillingScanQRCode;
