import React from 'react';
type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const MarkReadIcon: React.FC<Props> = ({ height, width, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={`${height ?? 32}px`}
      viewBox="0 0 33 32"
      width={`${width ?? 33}px`}
      fill={color ?? 'currentColor'}
    >
      <g clipPath="url(#clip0_24405_317218)">
        <path
          d="M16.5003 25.3333C16.5003 20.1733 20.6737 15.9999 25.8337 15.9999C27.2737 15.9999 28.6203 16.3333 29.8337 16.9066V7.99992C29.8337 6.53325 28.6337 5.33325 27.167 5.33325H5.83366C4.36699 5.33325 3.16699 6.53325 3.16699 7.99992V23.9999C3.16699 25.4666 4.36699 26.6666 5.83366 26.6666H16.607C16.5403 26.2266 16.5003 25.7866 16.5003 25.3333ZM5.83366 7.99992L16.5003 14.6666L27.167 7.99992V10.6666L16.5003 17.3333L5.83366 10.6666V7.99992ZM23.6203 29.3333L18.9003 24.6133L20.7803 22.7333L23.607 25.5599L29.2603 19.9066L31.167 21.7866L23.6203 29.3333Z"
          fill={color ?? 'currentColor'}
        />
      </g>
      <defs>
        <clipPath id="clip0_24405_317218">
          <rect width="32" height="32" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default MarkReadIcon;
