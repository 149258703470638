import React from 'react';
import { ProductTraceReportTypeT } from '../../interfaces/productTrace';
import Icon from '../Icon';
import Text from '../Text';

type Props = {
  status: ProductTraceReportTypeT;
  size?: number;
  className?: string;
};

const ProductTraceStatus: React.FC<Props> = ({ status, size = 16, className = '' }) => {
  return (
    <>
      {status === 'trace' && (
        <span
          className={`tw-inline-flex tw-gap-1 tw-px-2 tw-py-1 tw-bg-white tw-rounded tw-border-primary ${className}`}
        >
          <Icon name="icon-track_changes" size={size} className="tw-text-grey-700" />
          <Text variant="labelSm" className="tw-text-grey-800">
            Trace
          </Text>
        </span>
      )}
      {status === 'productRecall' && (
        <span
          className={`tw-inline-flex tw-gap-1 tw-px-2 tw-py-1 tw-bg-grey-200 tw-rounded tw-border-primary ${className}`}
        >
          <Icon name="icon-recall_filled" size={size} className="tw-text-error-500" />
          <Text variant="labelSm" className="tw-text-grey-800">
            Recall
          </Text>
        </span>
      )}
      {status === 'onHold' && (
        <span
          className={`tw-inline-flex tw-gap-1 tw-px-2 tw-py-1 tw-bg-grey-200 tw-rounded tw-border-primary ${className}`}
        >
          <Icon name="icon-recall_onhold_filled" size={size} className="tw-text-warning-300" />
          <Text variant="labelSm" className="tw-text-grey-800">
            On Hold
          </Text>
        </span>
      )}

      {status === 'resolved' && (
        <span
          className={`tw-inline-flex tw-gap-1 tw-px-2 tw-py-1 tw-bg-grey-200 tw-rounded tw-border-primary ${className}`}
        >
          <Icon name="icon-recall_resolved_filled" size={size} className="tw-text-success-500" />
          <Text variant="labelSm" className="tw-text-grey-800">
            Resolved
          </Text>
        </span>
      )}
    </>
  );
};

export default ProductTraceStatus;
