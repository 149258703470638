import React, { useMemo } from 'react';
import { ProductTraceI } from '../../interfaces/productTrace';
import Text from '../Text';
import Icon from '../Icon';
import ProductTrackAffectedPartnersTable from '../tables/ProductTraceAffectedPartners';

type Props = {
  data?: ProductTraceI;
  partnersAffected?: number;
  ecoTOTEsAffected?: number;
};

export const ProductTracePartners: React.FC<Props> = ({ data, partnersAffected = 0, ecoTOTEsAffected = 0 }) => {
  const partnersWithNotify = useMemo(() => {
    const selectedPartnerIds = data?.selectedPartnerIds ?? [];
    const mappedData = data?.partners.map((partner) => {
      return {
        ...partner,
        isEmailNotify: selectedPartnerIds.includes(partner.id) ?? false,
      };
    });

    return mappedData ?? [];
  }, [data]);

  return (
    <div className="tw-py-4 tw-px-4">
      <div className="tw-flex tw-gap-12 tw-mb-6">
        <Text variant="labelMd" className="tw-flex tw-items-center tw-gap-1">
          <Icon name="icon-supervised_user_circle_outline" size={20} />
          {partnersAffected} Partners Affected
        </Text>
        <Text variant="labelMd" className="tw-flex tw-items-center tw-gap-1">
          <Icon name="icon-eco-tote" size={20} />
          {ecoTOTEsAffected} ecoTOTEs Affected
        </Text>
      </div>
      <ProductTrackAffectedPartnersTable dataSource={partnersWithNotify} reportType={data?.status} />
    </div>
  );
};
