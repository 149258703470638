import React, { ReactNode } from 'react';
import './ScanQRCodeScreen.scss';
import { ScanCode } from '../Scanner';
import Text from '../../Text';
import usePageTimeTracker from '../../../hooks/usePageTimeTracker';

type Props = {
  stopDecodingScanner: boolean;
  handleScanOrManualSubmit: (result: { text: string; isManualInput: boolean }) => void;
  setStopDecodingScanner: (_: any) => void;
  scanTabsContent?: ReactNode;
  title?: string;
  isDisplayTitle?: boolean;
  pageName?: string;
};

const SharedScanQRCode: React.FC<Props> = ({
  stopDecodingScanner,
  setStopDecodingScanner,
  handleScanOrManualSubmit,
  scanTabsContent,
  title,
  isDisplayTitle = true,
  pageName,
}) => {
  usePageTimeTracker(pageName ?? '');
  return (
    <div className="shared-hardware-scan-qrcode-screen">
      {isDisplayTitle && (
        <Text variant="h2" className="tw-w-full tw-text-center tw-mt-4">
          {title ?? 'Scan by QR/Bar Code'}
        </Text>
      )}
      {scanTabsContent}
      <ScanCode
        stopDecodingScanner={stopDecodingScanner}
        setStopDecodingScanner={setStopDecodingScanner}
        handleScanOrManualSubmit={handleScanOrManualSubmit}
      />
    </div>
  );
};

export default SharedScanQRCode;
