import React, { useEffect, useState } from 'react';
import { CurrentUserI } from '../../interfaces/auth';
import { Button, MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import DropdownApp from '../Dropdown';
import Text from '../Text';
import LogoutIcon from '../../svgs/LogoutIcon';
import { AttachmentI } from '../../interfaces/attachments';
import Divider from '../Divider';
import PlatformIssueReportingModal from '../PlatformIssueReportingModal';
import PartnerCreationRequestDrawer from '../PartnerCreationRequestDrawer';
import Notification from '../Notification';

type Props = {
  currentUser: CurrentUserI;
  handleLogout: (_: any) => void;
};

const HeaderAccountInfoDesktop: React.FC<Props> = ({ currentUser, handleLogout }) => {
  const [avatar, setAvatar] = useState<string>('');
  const [reportingModalOpen, setReportingModalOpen] = useState(false);
  const [partnerCreationDrawerOpen, setPartnerCreationDrawerOpen] = useState(false);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link to={'/profile'} className="hover:tw-opacity-100">
          <Text variant="bodyMd" className={`dropdown-item tw-flex tw-items-center tw-gap-2 tw-font-bold`}>
            <Icon name="icon-person_outline" size={20} />
            <span>Update Profile</span>
          </Text>
        </Link>
      ),
    },
    {
      key: 'divider-1',
      label: <Divider />,
    },
    currentUser.type === 'partnerAdmin'
      ? {
          key: 'issue-reporting',
          label: (
            <Button
              className={`dropdown-item tw-flex tw-items-center tw-gap-2 tw-font-bold tw-cursor-pointer tw-shadow-none hover:!tw-text-grey-900 hover:tw-bg-primary-50`}
              onClick={() => {
                setPartnerCreationDrawerOpen(true);
              }}
            >
              <Icon name="icon-person_add" size={20} />
              <span>Request Partner Creation</span>
            </Button>
          ),
        }
      : null,
    {
      key: 'partner-creation-request',
      label: (
        <Button
          className={`dropdown-item tw-flex tw-items-center tw-gap-2 tw-font-bold tw-cursor-pointer tw-shadow-none hover:tw-bg-primary-50 hover:!tw-text-grey-900`}
          onClick={() => {
            setReportingModalOpen(true);
          }}
        >
          <Icon name="icon-chat_bubble_outline" size={20} />
          <span>Contact Us</span>
        </Button>
      ),
    },
    {
      key: 'divider-2',
      label: <Divider />,
    },
    {
      key: '2',
      label: (
        <Button
          className="dropdown-item tw-flex tw-justify-start !tw-p-0 !tw-leading-5 tw-w-full tw-border-none tw-text-left hover:!tw-text-grey-900 tw-shadow-none hover:tw-bg-primary-50"
          onClick={handleLogout}
        >
          <Text variant="bodyMd" className={`!tw-flex tw-gap-2 tw-font-bold tw-items-center`}>
            <LogoutIcon />
            <span>Log out</span>
          </Text>
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (currentUser) {
      const firstLogo = currentUser.attachments.filter((file: AttachmentI) => file.categoryName === 'logo');
      setAvatar(firstLogo?.[0]?.url);
    }
  }, [currentUser]);

  return (
    <div className="account-info">
      <Notification />
      <div className="avatar">
        <img
          src={
            avatar ??
            `https://ui-avatars.com/api/?length=1&name=${
              currentUser?.username ? currentUser?.username : currentUser?.email
            }&size=32&font-size=0.4&bold=true&background=5f5f5f&color=FFFFFF&rounded=true`
          }
          alt=""
        />
      </div>
      <div className="info">
        <Text component="p" className="!tw-block tw-truncate xs:tw-max-w-[150px]" variant="inputLabelSm">
          {currentUser?.username}
        </Text>
        <Text component="p" className="!tw-block" variant="capt1">
          {currentUser?.type}
        </Text>
      </div>
      <DropdownApp
        icon={<Icon name="icon-expand_more" />}
        menu={{ items }}
        size="medium"
        placement="bottomRight"
        type="primary"
        className="!tw-p-0 !tw-self-center !tw-bg-white !tw-text-grey-900 hover:!tw-bg-primary-50 [&.ant-dropdown-open]:!tw-bg-primary-50"
      />
      <PlatformIssueReportingModal
        open={reportingModalOpen}
        setOpen={setReportingModalOpen}
        currentUser={currentUser}
      />
      <PartnerCreationRequestDrawer open={partnerCreationDrawerOpen} setOpen={setPartnerCreationDrawerOpen} />
    </div>
  );
};

export default HeaderAccountInfoDesktop;
