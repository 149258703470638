import { BaseResponse } from '../interfaces';
import {
  ProductTraceCollectionI,
  ProductTraceI,
  ProductTraceScanResT,
  ProductTraceSearchParamsI,
  ProductTraceVerifyReqT,
} from '../interfaces/productTrace';
import { handleFilterParams } from '../utils/global';
import { api } from './api';
import { useFetch } from './reactQuery';

export const useGetProductTrace = (code?: string) => {
  const { data: response, error, isLoading, refetch } = useFetch<{ data: ProductTraceI }>(`product-traces/${code}`);
  const data = response?.data;
  return { data, error, isLoading, refetch };
};

export const getProductTracesList = async (params?: ProductTraceSearchParamsI) => {
  const { data: response } = await api.get<BaseResponse<ProductTraceCollectionI>>(
    `product-traces?${handleFilterParams(params)}`,
  );
  return response;
};

export const createProductTrace = async (values: Partial<ProductTraceI>) => {
  const { data: response }: any = await api.post(`product-traces`, values);
  return response.data;
};

export const updateProductTrace = async ({ id, values }: { id: string; values: Partial<ProductTraceI> }) => {
  const { data: response }: any = await api.patch(`product-traces/${id}`, values);
  return response.data;
};

export const doVerifyProductTrace = async (params: ProductTraceVerifyReqT) => {
  const { data: response } = await api.post<BaseResponse<ProductTraceI>>(`product-traces/verify`, params);
  return response.data;
};

export const doScanProductTrace = async (params: { productSku: string }) => {
  const { data: response } = await api.post<BaseResponse<ProductTraceScanResT>>(`product-traces/scan`, params);
  return response.data;
};

export const updateProductTraceStatus = async ({ code, values }: { code?: string; values: { status: string } }) => {
  const { data: response }: any = await api.patch(`product-traces/${code}/status`, values);
  return response.data;
};
