import React, { useEffect } from 'react';
import { ProductTraceI } from '../../interfaces/productTrace';
import { Form, Radio } from 'antd';
import Text from '../Text';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import ProductTraceStatus from './Status';
import './UpdateStatus.scss';

type Props = {
  data?: ProductTraceI;
  handleSubmit: (_: any) => void;
  onCancel: () => void;
};

const ProductTraceUpdateStatus: React.FC<Props> = ({ data, handleSubmit, onCancel }) => {
  const [form] = Form.useForm();
  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        status: data.status,
      });
    }
  }, [data]);

  return (
    <div className="product-trace-update-status-container">
      <Form form={form} scrollToFirstError layout="vertical" requiredMark={false} colon={false} onFinish={handleSubmit}>
        <Text variant="h3" className="tw-mb-6">
          Update Status and Send Emails
        </Text>
        <Text variant="bodyMd" className="tw-mb-6">
          Please note that this report status will affect the status of all hardware involved, and send an email to
          notify of corresponding action to all partners involved.
        </Text>
        <div className="FormContent">
          <Form.Item name="status">
            <Radio.Group className="tw-flex tw-gap-3 tw-flex-col">
              <Radio value="onHold">
                <span>
                  <ProductTraceStatus status="onHold" />
                </span>{' '}
                <Text variant="bodyMd">
                  Temporarily stop further circulation of affected hardware and await further instruction
                </Text>
              </Radio>
              <Radio value="productRecall">
                <div className="tw-flex tw-flex-col">
                  <span>
                    <ProductTraceStatus status="productRecall" />
                  </span>{' '}
                  <Text variant="bodyMd">
                    Hardware needs to be returned to owners or purged/cleaned as per partner enitities’s standard
                  </Text>
                </div>
              </Radio>
              <Radio value="resolved">
                <div className="tw-flex tw-flex-col">
                  <span>
                    <ProductTraceStatus status="resolved" />
                  </span>{' '}
                  <Text variant="bodyMd">No further actions need to be taken</Text>
                </div>
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <PrimaryButton className="tw-w-full tw-mb-3" label="Update" htmlType="submit" />
        <SecondaryButton className="tw-w-full" label="Cancel" onClick={onCancel} />
      </Form>
    </div>
  );
};

export default ProductTraceUpdateStatus;
