import React, { useMemo, useState } from 'react';
import { HardwareItemI } from '../../../interfaces/hardware';
import { getTypeFromApi, handleDisplayIconByTypeFromApi } from '../../../utils/hardware';
import Text from '../../Text';
import { CommonStatus } from '../../CommonStatus';
import Icon from '../../Icon';
import NetworkStatus from '../NetworkStatus';
import { DateFormat } from '../../../utils/global';
import Divider from '../../Divider';
import DangerButton from '../../buttons/DangerButton';
import TrashIcon from '../../../svgs/TrashIcon';
import { SiteI } from '../../../interfaces/site';
import FaultyReportButton from '../../buttons/FaultyReportButton';
import TooltipIcon from '../../buttons/TooltipIcon';

type Props = {
  item: HardwareItemI;
  canDelete?: boolean;
  site?: SiteI;
  handleDelete?: (_?: HardwareItemI) => void;
};

const HardwareDetailsCard: React.FC<Props> = ({ item, canDelete = true, site, handleDelete }) => {
  const [condition, setCondition] = useState<string>();

  const data: HardwareItemI = useMemo(() => {
    if (item?.currentData) return item.currentData;
    return item;
  }, [item]);

  const onDeleteClick = () => {
    if (item?.error) {
      handleDelete?.(item);
    } else {
      handleDelete?.(data);
    }
  };

  const renderInfoByType = () => {
    return (
      <Text variant="inputLabelSm" className="tw-flex tw-items-center tw-gap-0.5 tw-mb-2">
        {data?.type === 'ecotote' && (
          <>
            <Icon name="icon-water-drop" size={12} className=" tw-text-grey-700" />
            <span>
              {data?.ecoTote?.lastKnownVolume !== null
                ? (Number(data?.ecoTote?.lastKnownVolume) / 1000).toFixed(2)
                : (0).toFixed(2)}
              L
            </span>
          </>
        )}
        {data?.type === 'ecoplant' && data?.ecoPlant && (
          <>
            <Icon name="icon-date_range" size={12} className=" tw-text-grey-700" />
            <span>{data?.ecoPlant?.lastFilledDate ? DateFormat(data?.ecoPlant?.lastFilledDate) : ''}</span>
          </>
        )}
        {data?.type === 'smartpour' && <NetworkStatus status={data?.smartPour?.network} />}
      </Text>
    );
  };

  return (
    <div className="tw-bg-white tw-rounded-lg tw-shadow-simple tw-flex tw-flex-col tw-min-w-[150px] tw-min-h-[150px]">
      <div className="tw-flex tw-flex-col tw-justify-between tw-h-full tw-py-4 tw-px-4">
        <div className="tw-h-full">
          {data && !item?.error && (
            <>
              {data?.type && (
                <div className="tw-flex tw-justify-between tw-items-center">
                  <Text variant="capt1" className="tw-flex tw-items-center tw-gap-0.5">
                    {handleDisplayIconByTypeFromApi(data?.type, { color: '#191919', width: 16, height: 16 })}
                    <span>{getTypeFromApi(data)}</span>
                  </Text>
                  {item.isManualInput && (
                    <TooltipIcon
                      icon="icon-keyboard"
                      text="Manual Input"
                      placement="top"
                      iconClassName={'tw-text-grey-900'}
                    />
                  )}
                </div>
              )}
              <Text variant="inputLabelLg" className="tw-mt-1 tw-text-primary-900">{`${data?.serialCode}`}</Text>
              {data?.owner && (
                <Text variant="inputLabelSm" className="tw-flex tw-items-center tw-gap-1 tw-mb-2">
                  <Icon name="icon-person" size={12} className=" tw-text-grey-700" />
                  <span>{data?.owner?.displayName}</span>
                </Text>
              )}
              <Text variant="inputLabelSm" className="tw-flex tw-items-center tw-gap-0.5 tw-mb-2">
                <>
                  <Icon name="icon-location_on" size={12} className=" tw-text-grey-700" />
                  {site ? <span>{site?.displayName}</span> : !data?.site ? 'Unassigned Site' : data?.site?.displayName}
                </>
              </Text>
              {data?.type && renderInfoByType()}
              <span className="tw-flex tw-items-center tw-gap-0.5">
                {data?.status && <CommonStatus status={data?.status ?? ''} />}
                {data?.condition && <CommonStatus status={condition ?? data?.condition} />}
              </span>
            </>
          )}
          {item?.error && (
            <div className="tw-flex tw-items-start tw-justify-center tw-flex-col tw-h-full">
              <Text
                variant="inputLabelLg"
                className="tw-mt-1 tw-text-primary-900 tw-mb-2"
              >{`${item?.serialCode}`}</Text>
              <Text>
                <CommonStatus status={'inactive'} text={item?.error} />
              </Text>
            </div>
          )}
        </div>
      </div>
      <Divider />
      <div className="tw-flex tw-justify-between tw-py-2 tw-px-3 tw-min-h-[26px]">
        {!item?.error ? (
          <FaultyReportButton data={item} hardwareType={item.type} setCondition={setCondition} />
        ) : (
          <span className="tw-leading-5 tw-mt-3"></span>
        )}
        {canDelete && <DangerButton icon={<TrashIcon width={12} height={12} />} onOk={onDeleteClick} />}
      </div>
    </div>
  );
};

export default HardwareDetailsCard;
