import React, { useState } from 'react';
import Text from '../Text';
import { Radio } from 'antd';
import { Notice } from '../Notice';
import { ProductTraceReportTypeT } from '../../interfaces/productTrace';
import SecondaryButton from '../buttons/SecondaryButton';
import PrimaryButton from '../buttons/PrimaryButton';

type Props = {
  reportType?: ProductTraceReportTypeT;
  setCurrentStep: (_: number) => void;
  setReportType: (_: ProductTraceReportTypeT) => void;
  handleVerify: () => void;
};

const ProductTraceChooseReportType: React.FC<Props> = ({ reportType, setReportType, setCurrentStep, handleVerify }) => {
  const [radioValue, setRadioValue] = useState<'trace' | 'notify'>('trace');

  const handleRadioChange = (e: any) => {
    setRadioValue(e.target.value);

    if (e.target.value === 'trace') {
      setReportType('trace');
    } else setReportType('onHold');
  };

  const handleNotifyChange = (e: any) => {
    setReportType(e.target.value);
  };

  const onContinue = () => {
    handleVerify();
  };

  const onPreviousStepClick = () => {
    setCurrentStep(2);
  };

  return (
    <>
      <div className="tw-w-[702px] tw-px-4 tw-my-4 tw-mx-auto">
        <Text variant="bodyLg" className="tw-text-grey-700 tw-w-full tw-text-center">
          Please choose a report type
        </Text>
        <div className="tw-mt-4">
          <Radio.Group onChange={handleRadioChange} value={radioValue}>
            <div className="tw-flex tw-flex-col tw-gap-6">
              <div className="tw-bg-white tw-w-full tw-rounded-lg tw-p-6 tw-shadow-simple">
                <Radio value="trace" className="tw-w-full tw-leading-4">
                  <Text variant="bodyLgB">Trace only</Text>
                </Radio>
                <Text variant="bodyLg" className="tw-ml-6">
                  Trace the current circulation of the Product of lot number
                </Text>
              </div>
              <div className="tw-bg-white tw-w-full tw-rounded-lg tw-p-6 tw-shadow-simple">
                <Radio value="notify" className="tw-w-full tw-leading-4">
                  <Text variant="bodyLgB">Notify Partners</Text>{' '}
                </Radio>
                <Text variant="bodyLg" className="tw-ml-6">
                  Notify and request for immediate action from affected partners in the event of a Product fault
                </Text>
                {radioValue === 'notify' && (
                  <div className="tw-mt-6 tw-bg-grey-50 tw-border-primary tw-rounded-lg tw-p-4">
                    <Notice
                      type="warning"
                      closable={false}
                      description="Please note that this option will affect the status of all hardware involved, and send an email to notify of corresponding action to the partners involved."
                    />
                    <Text variant="inputLabelMd" className="tw-text-grey-700 tw-mt-4">
                      Set status for the Product Trace Report
                    </Text>
                    <div className="tw-mt-4">
                      <Radio.Group className="tw-flex tw-gap-6" onChange={handleNotifyChange} value={reportType}>
                        <div className="tw-flex-1">
                          <Radio value="onHold">
                            <Text variant="bodyLg">On Hold</Text>{' '}
                          </Radio>
                          <Text variant="bodyMd" className="tw-ml-6">
                            Request to pause circulation of affected hardware and await further instructions.
                          </Text>
                        </div>
                        <div className="tw-flex-1">
                          <Radio value="productRecall">
                            <Text variant="bodyLg">Product Recall</Text>{' '}
                          </Radio>
                          <Text variant="bodyMd" className="tw-ml-6">
                            Request to return affected hardware upstream for product recall or to be purged/cleaned
                          </Text>
                        </div>
                      </Radio.Group>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Radio.Group>
        </div>
      </div>
      <div className="hardware-footer-section">
        <div>
          <SecondaryButton label="Previous Step" onClick={onPreviousStepClick} />
          <PrimaryButton label={'Continue'} onClick={onContinue} />
        </div>
      </div>
    </>
  );
};

export default ProductTraceChooseReportType;
