import React from 'react';
import IconButton from './IconButton';
import RefreshIcon from '../../svgs/RefreshIcon';

type Props = {
  className?: string;
  doRefetch?: () => void;
  setIsLoading?: (isLoading: boolean) => void;
};

const RefreshButton: React.FC<Props> = ({ className, doRefetch, setIsLoading }) => {
  const handleRefresh = () => {
    if (setIsLoading) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
    doRefetch?.();
  };
  return (
    <IconButton
      className={`refresh-button tw-bg-white tw-text-primary-500 hover:!tw-bg-primary-800 hover:!tw-text-white ${className} tw-w-[40px]`}
      icon={<RefreshIcon size={16} />}
      onClick={() => handleRefresh()}
    />
  );
};

export default RefreshButton;
