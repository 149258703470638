import React from 'react';
import { Divider, Pagination } from 'antd';
import Icon from '../Icon';
import Text from '../Text';
import { IconName } from '../Icon/iconName';
import { DateFormat } from '../../utils/global';
import { ProductTraceI } from '../../interfaces/productTrace';
import { useNavigate } from 'react-router-dom';
import ProductTraceStatus from '../ProductTrace/Status';

interface Props {
  data: ProductTraceI[];
  pagination: any;
  onPaginationChange: (params: { page: number; per_page: number }) => void;
}
const ProductTracesCardList: React.FC<Props> = ({ data, pagination, onPaginationChange }) => {
  const navitate = useNavigate();

  const handleCardClick = (item: ProductTraceI) => {
    navitate(`/product-traces/${item.code}`);
  };

  const renderField = (props: { icon: IconName; value: string }) => {
    return (
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
        <Icon name={props.icon} className="tw-text-grey-700 tw-text-xs tw-min-w-4" size={16} />
        <Text variant="inputLabelSm" className="flex-1">
          {props.value}
        </Text>
      </div>
    );
  };
  return (
    <section className="tw-flex tw-flex-col">
      <div className="card-list">
        {data.map((item: ProductTraceI) => (
          <div className="hardware-card-item card-item" key={item.id}>
            <button className="item" onClick={() => handleCardClick(item)}>
              {/* {item?.remarks && <Icon name="icon-text_snippet" className="btn-more-action" size={16} />} */}
              <Text variant="inputLabelSm" className="tw-text-grey-700">
                Traceability Report
              </Text>
              <div className="tw-flex tw-flex-col tw-gap-4 tw-w-full">
                <Text variant="bodyLgB" className="tw-text-primary-800">
                  {item.code}
                </Text>
                <div className="tw-flex tw-flex-col tw-gap-2">
                  {renderField({ icon: 'icon-all_inbox', value: `${item?.ecoToteIds.length ?? 0} Hardware` })}
                  <span>
                    <ProductTraceStatus status={item.status} />
                  </span>
                </div>
                <Divider className="tw-m-0" />
                {renderField({ icon: 'icon-calendar_today', value: `${DateFormat(item?.createdAt)}` })}
              </div>
            </button>
          </div>
        ))}
      </div>
      <Pagination
        style={{ alignSelf: 'flex-end' }}
        total={pagination.total}
        pageSize={pagination.pageSize}
        showTotal={(total) => `${total} Items`}
        current={pagination.current}
        onChange={(page, pageSize) => onPaginationChange({ page, per_page: pageSize })}
        pageSizeOptions={[5, 10, 20, 50]}
        showSizeChanger
      />
    </section>
  );
};

export default ProductTracesCardList;
