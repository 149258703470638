import React, { useMemo } from 'react';
import { EChartsOption } from 'echarts';
import { ProductTraceI } from '../../interfaces/productTrace';
import ECharts from '../charts/ECharts';
import TooltipIcon from '../buttons/TooltipIcon';
import Text from '../Text';
import DetailsCard from '../Dashboard/DetailsCard';
import {
  ProductTraceConsumedTooltip,
  ProductTraceInCirculationTooltip,
  ProductTraceTotalIntroducedTooltip,
} from '../../constants/productTrace';

type Props = {
  data?: ProductTraceI['situationData']['currentVolume'];
};

const ProductTraceCurrentVolumeChart: React.FC<Props> = ({ data }) => {
  const pieChartOption: EChartsOption = useMemo(() => {
    const totalIntroduced = data && data?.totalIntroduced ? data.totalIntroduced / 1000 : 0;
    const circulation = data && data?.circulation ? data.circulation / 1000 : 0;
    const depleted = data && data?.depleted ? data.depleted / 1000 : 0;

    return {
      tooltip: {
        show: false,
      },
      legend: {
        show: false,
      },
      series: [
        {
          name: 'Current Volume',
          type: 'pie',
          radius: ['60%', '90%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            formatter: ['{a|Total Introduced (L)}', '{tooltipIcon|}', '{b|' + totalIntroduced.toFixed(2) + '}'].join(
              '\n',
            ),
            rich: {
              a: {
                color: '#898989',
                fontSize: 16,
                lineHeight: 24,
                fontWeight: 500,
                fontFamily: 'Poppins',
              },
              tooltipIcon: {
                height: 20,
                width: 20,
                align: 'center',
                marginTop: 0,
              },
              b: {
                color: '#191919',
                fontFamily: 'Poppins',
                fontSize: 34,
                fontWeight: 500,
                lineHeight: 42,
                letterSpacing: '-0.68px',
                display: 'block',
              },
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            { value: circulation, name: 'In Circulation (L)', itemStyle: { color: '#ACD49E' } },
            { value: depleted, name: 'Consumed (L)', itemStyle: { color: '#FCB2A7' } },
          ],
        },
      ],
    };
  }, [data]);

  return (
    <div className="tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-6 sm:tw-p-4">
      <Text variant="h3" className="tw-mb-4">
        Current Volume
      </Text>
      <div className="tw-flex tw-items-center tw-justify-between tw-flex-wrap sm:tw-justify-center">
        <div className="tw-relative tw-w-full tw-h-[290px] tw-max-w-[290px] sm:tw-max-w-full">
          <div className="tw-w-full tw-h-full tw-absolute tw-left-0 tw-top-[-12px] tw-flex tw-items-center tw-justify-center">
            <span className="tw-z-10 tw-h-5">
              <TooltipIcon text={ProductTraceTotalIntroducedTooltip} iconSize={16} buttonClassName="tw-w-4 !tw-h-4" />
            </span>
          </div>
          <div className="tw-flex tw-justify-center tw-w-full tw-h-full">
            <ECharts option={pieChartOption} style={{ width: '100%', height: '100%' }} />
          </div>
        </div>
        <div className="sm:tw-w-full tw-flex-1 tw-min-w-[150px] lg:tw-max-w-[200px]">
          <DetailsCard
            tooltipText={ProductTraceInCirculationTooltip}
            percentage={
              data && data?.totalIntroduced !== 0 ? ((data?.circulation / data.totalIntroduced) * 100).toFixed(0) : 0
            }
            title="In Circulation (L)"
            color="#ACD49E"
            totalNumber={data && data?.circulation !== 0 ? (data?.circulation / 1000).toFixed(2) : 0}
          />
          <DetailsCard
            tooltipText={ProductTraceConsumedTooltip}
            percentage={
              data && data?.totalIntroduced !== 0 ? ((data?.depleted / data.totalIntroduced) * 100).toFixed(0) : 0
            }
            title="Consumed (L)"
            color="#FCB2A7"
            totalNumber={data && data?.depleted !== 0 ? (data?.depleted / 1000).toFixed(2) : 0}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductTraceCurrentVolumeChart;
