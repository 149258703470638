import React, { useMemo } from 'react';
import { EChartsOption } from 'echarts';
import { ProductTraceI } from '../../interfaces/productTrace';
import ECharts from '../charts/ECharts';
import TooltipIcon from '../buttons/TooltipIcon';
import Text from '../Text';
import DetailsCard from '../Dashboard/DetailsCard';
import {
  VolumeHeldConsumeTooltip,
  VolumeHeldFillingTooltip,
  VolumeHeldInCirculationTooltip,
  VolumeHeldStorageTooltip,
} from '../../constants/productTrace';

type Props = {
  data?: ProductTraceI['situationData']['volumeHeldSiteTypes'];
};

const ProductTraceVolumeHeldChart: React.FC<Props> = ({ data }) => {
  const pieChartOption: EChartsOption = useMemo(() => {
    const totalInCirculation = data ? data?.circulation / 1000 : 0;
    const consume = data ? data?.consumeVenues / 1000 : 0;
    const fill = data ? data?.bottlingPlants / 1000 : 0;
    const storage = data ? data?.warehouses / 1000 : 0;

    return {
      tooltip: {
        show: false,
      },
      legend: {
        show: false,
      },
      series: [
        {
          name: 'Volume Held in Site Types',
          type: 'pie',
          radius: ['60%', '90%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            formatter: ['{a|In Circulation (L)}', '{tooltipIcon|}', '{b|' + totalInCirculation.toFixed(2) + '}'].join(
              '\n',
            ),
            rich: {
              a: {
                color: '#898989',
                fontSize: 16,
                lineHeight: 24,
                fontWeight: 500,
                fontFamily: 'Poppins',
              },
              tooltipIcon: {
                height: 20,
                width: 20,
                align: 'center',
                marginTop: 0,
              },
              b: {
                color: '#191919',
                fontFamily: 'Poppins',
                fontSize: 34,
                fontWeight: 500,
                lineHeight: 42,
                letterSpacing: '-0.68px',
                display: 'block',
              },
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            { value: consume, name: 'Consume (L)', itemStyle: { color: '#437132' } },
            { value: fill, name: 'Filling (L)', itemStyle: { color: '#94C482' } },
            { value: storage, name: 'Storage (L)', itemStyle: { color: '#E0EEDB' } },
          ],
        },
      ],
    };
  }, [data]);

  return (
    <div className="tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-p-6 sm:tw-p-4">
      <Text variant="h3" className="tw-mb-4">
        Volume Held in Site Types
      </Text>
      <div className="tw-flex tw-items-center tw-justify-between tw-flex-wrap sm:tw-justify-center">
        <div className="tw-relative tw-w-full tw-h-[290px] tw-max-w-[290px] sm:tw-max-w-full">
          <div className="tw-w-full tw-h-full tw-absolute tw-left-0 tw-top-[-12px] tw-flex tw-items-center tw-justify-center">
            <span className="tw-z-10 tw-h-5">
              <TooltipIcon text={VolumeHeldInCirculationTooltip} iconSize={16} buttonClassName="tw-w-4 !tw-h-4" />
            </span>
          </div>
          <div className="tw-flex tw-justify-center tw-w-full tw-h-full">
            <ECharts option={pieChartOption} style={{ width: '100%', height: '100%' }} />
          </div>
        </div>
        <div className="sm:tw-w-full tw-flex-1 tw-min-w-[150px] lg:tw-max-w-[200px]">
          <DetailsCard
            tooltipText={VolumeHeldConsumeTooltip}
            percentage={
              data && data?.consumeVenues !== 0 ? ((data?.consumeVenues / data.circulation) * 100).toFixed(0) : 0
            }
            title="Consume (L)"
            color="#437132"
            totalNumber={data && data?.consumeVenues !== 0 ? (data?.consumeVenues / 1000).toFixed(2) : 0}
          />
          <DetailsCard
            tooltipText={VolumeHeldFillingTooltip}
            percentage={
              data && data?.bottlingPlants !== 0 ? ((data?.bottlingPlants / data.circulation) * 100).toFixed(0) : 0
            }
            title="Filling (L)"
            color="#94C482"
            totalNumber={data && data?.bottlingPlants !== 0 ? (data?.bottlingPlants / 1000).toFixed(2) : 0}
          />
          <DetailsCard
            tooltipText={VolumeHeldStorageTooltip}
            percentage={data && data?.warehouses !== 0 ? ((data?.warehouses / data.circulation) * 100).toFixed(0) : 0}
            title="Storage (L)"
            color="#E0EEDB"
            totalNumber={data && data?.warehouses !== 0 ? (data?.warehouses / 1000).toFixed(2) : 0}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductTraceVolumeHeldChart;
