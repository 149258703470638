import React, { useMemo } from 'react';
import { ProductTraceI } from '../../interfaces/productTrace';
import TooltipIcon from '../buttons/TooltipIcon';
import Text from '../Text';
import EmailImg from '../../images/EmailImage.png';
import { Image } from 'antd';
import HorizonBarItem from '../Dashboard/HorizonBarItem';
import { EmailsSentByStatusTooltip, TotalEmailsSentTooltip } from '../../constants/productTrace';

type SortedDataT = { label: string; slug: string; opened: number; notOpened: number; total: number };

type Props = {
  data?: ProductTraceI['emailInfo'];
};

const ProductTraceEmailsSentChart: React.FC<Props> = ({ data }) => {
  const sortedData: SortedDataT[] = useMemo(() => {
    if (data) {
      const sortedArray = [
        {
          label: 'On Hold',
          slug: 'onHold',
          opened: data.openedOnHold,
          notOpened: data.notOpenedOnHold,
          total: data.openedOnHold + data.notOpenedOnHold,
        },
        {
          label: 'Recall',
          slug: 'recall',
          opened: data.openedProductRecall,
          notOpened: data.notOpenedProductRecall,
          total: data.openedProductRecall + data.notOpenedProductRecall,
        },
        {
          label: 'Resolved',
          slug: 'resolved',
          opened: data.openedResolved,
          notOpened: data.notOpenedResolved,
          total: data.openedResolved + data.notOpenedResolved,
        },
      ];
      return sortedArray.sort((a, b) => b.total - a.total);
    }
    return [];
  }, [data]);

  return (
    <div className="tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-p-6 tw-rounded-lg sm:tw-p-4">
      <Text variant="h3" className="tw-mb-4">
        Volume Held in Site Types
      </Text>
      <div className="tw-flex tw-items-start tw-gap-6 tw-mb-6">
        <div className="tw-flex-1 tw-flex tw-gap-3 tw-rounded-lg tw-border tw-border-solid tw-border-grey-300 tw-p-3">
          <Image src={EmailImg} preview={false} />
          <div className="tw-flex tw-flex-col">
            <Text variant="h4" className="tw-text-grey-600 tw-flex tw-gap-1 tw-items-center">
              Total Emails Sent <TooltipIcon text={TotalEmailsSentTooltip} />
            </Text>
            <Text variant="h1">{(data?.totalSent ?? 0).toLocaleString()}</Text>
          </div>
        </div>
      </div>
      <div className="tw-bg-grey-50 tw-border tw-border-solid tw-border-grey-300 tw-p-6 tw-rounded-lg tw-mt-10 sm:tw-p-4">
        <span className="tw-flex tw-items-center tw-mb-5 tw-gap-2">
          <Text variant="inputLabelMd" className="tw-text-grey-700 tw-flex tw-gap-1 tw-items-center">
            Email by Trace Report Status <TooltipIcon text={EmailsSentByStatusTooltip} />
          </Text>
        </span>
        <div className="tw-overflow-auto tw-min-h-[130px]">
          {sortedData?.map((item: SortedDataT) => {
            const maxVolume = sortedData[0].total;
            return (
              <HorizonBarItem
                key={item?.slug}
                title={item?.label}
                valueText={`${item?.total}`}
                itemTotalValue={((item?.total ?? 0) / maxVolume) * 100}
                maxValue={sortedData[0].total * 100}
                itemValue={((item?.opened ?? 0) / maxVolume) * 100}
                barColor="#3AA5DA"
                backgroundColor="#c9c9c9"
                labelGap={120}
              />
            );
          })}
          <div className="tw-flex tw-gap-9 tw-justify-center tw-w-full xs:tw-gap-3">
            <span className="tw-flex tw-justify-center tw-items-center tw-gap-x-1">
              <span className="tw-min-w-4 tw-h-4 tw-bg-secondary-300 tw-rounded-[50%] tw-inline-block" />{' '}
              <Text variant="inputLabelSm">Opened</Text>
            </span>
            <span className="tw-flex tw-justify-center tw-items-center tw-gap-x-1">
              <span className="tw-min-w-4 tw-h-4 tw-bg-grey-light tw-rounded-[50%] tw-inline-block" />{' '}
              <Text variant="inputLabelSm">Not Opened</Text>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductTraceEmailsSentChart;
