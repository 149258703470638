import { Form, Input } from 'antd';
import React from 'react';
import PrimaryButton from '../buttons/PrimaryButton';
import './AllocationManual.scss';
import { HardwareScannedStateI } from '../../interfaces/hardware';
import AllocationScanTabsContent from './AllocationScanTabsContent';

type Props = {
  scannedData: HardwareScannedStateI[];
  handleScanOrManualSubmit: (result: { text: string; isManualInput: boolean }) => void;
};

const AllocationManual: React.FC<Props> = ({ scannedData, handleScanOrManualSubmit }) => {
  const [form] = Form.useForm();

  const onFinish = (values: { text: string }) => {
    const params = {
      text: values.text,
      isManualInput: true,
    };
    handleScanOrManualSubmit(params);
    form.resetFields();
  };

  return (
    <div className="component-hardware-register-manual-step">
      <h2>Manual Input</h2>
      <AllocationScanTabsContent scannedData={scannedData} />
      <Form form={form} requiredMark={false} colon={false} scrollToFirstError onFinish={onFinish} layout="vertical">
        <div className="form-container">
          <div className="form-content">
            <Form.Item
              label="Enter Barcode Number"
              name="text"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input placeholder="Type Barcode" />
            </Form.Item>
          </div>
        </div>
        <PrimaryButton label="Scan" htmlType="submit" />
      </Form>
    </div>
  );
};

export default AllocationManual;
