import React, { ReactNode } from 'react';
import FillingScanTabsContent from './ScanTabsContent';
import { ScanCode } from '../shared/Scanner';
import Text from '../Text';
import usePageTimeTracker from '../../hooks/usePageTimeTracker';

type Props = {
  toteScanned?: { serialCode: string }[];
  scanNoticeMessage: string | ReactNode;
  stopDecodingScanner: boolean;
  pageName?: string;
  handleScanOrManualSubmit: (result: { text: string; isManualInput: boolean }) => void;
  setStopDecodingScanner: (_: any) => void;
};

const FillingEcoToteScanQRCode: React.FC<Props> = ({
  toteScanned,
  scanNoticeMessage,
  stopDecodingScanner,
  pageName,
  setStopDecodingScanner,
  handleScanOrManualSubmit,
}) => {
  usePageTimeTracker(pageName ?? '');

  return (
    <div className="component-hardware-filling-scan-qrcode">
      <Text variant="h2" className="tw-w-full tw-text-center">
        Scan by QR/Bar Code
      </Text>
      <FillingScanTabsContent toteScanned={toteScanned} scanNoticeMessage={scanNoticeMessage} />
      <ScanCode
        stopDecodingScanner={stopDecodingScanner}
        setStopDecodingScanner={setStopDecodingScanner}
        handleScanOrManualSubmit={handleScanOrManualSubmit}
      />
    </div>
  );
};

export default FillingEcoToteScanQRCode;
