import { Dropdown, MenuProps, Space, Tabs, TabsProps } from 'antd';
import React, { useMemo, useState } from 'react';
import { Link, useLocation, useOutletContext } from 'react-router-dom';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import PlusIcon from '../../svgs/PlusIcon';
import { CurrentUserI } from '../../interfaces/auth';
import { handleAccessControl } from '../../utils/role';
import Icon from '../../components/Icon';
import Text from '../../components/Text';
import TrackingAllHardwareTab from './AllHardwareTab';
import TrackingGroupTab from './GroupTab';
import { PAGE_SIZE } from '../../constants';
import { HardwareGroupSearchParamsI, HardwareSearchParamsI } from '../../interfaces/hardware';
import { useQuery } from 'react-query';
import { getHardwareGroupsList, getHardwaresList } from '../../apis/hardwares';

const createTabItem = (key: string, label: string, children: React.ReactNode) => ({
  key,
  label: (
    <Text variant="labelLg" className="tab-title !tw-font-normal">
      <span>{label}</span>
    </Text>
  ),
  children,
});

const TrackingPage: React.FC = () => {
  const currentUser: CurrentUserI = useOutletContext();
  const trackingAccess = handleAccessControl(currentUser, 'hardwares');
  const groupAccess = handleAccessControl(currentUser, 'hardware-groups');
  const depleteAccess = handleAccessControl(currentUser, 'hardware-depletions');
  const location = useLocation();
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const hardwareQueryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    const search = urlSearch.get('search');
    const type = urlSearch.get('filter[type]')?.split(',') ?? [];
    const status = urlSearch.get('filter[status]')?.split(',') ?? [];
    const condition = urlSearch.get('filter[condition]')?.split(',') ?? [];
    const siteIds = urlSearch.get('filter[siteIds]')?.split(',') ?? [];
    const productTraceStatus = urlSearch.get('filter[productTraceStatus]')?.split(',') ?? [];
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      orderType,
      orderBy,
      viewMode,
      search,
      filter: { status, type, condition, siteIds, productTraceStatus },
    } as HardwareSearchParamsI & { viewMode?: string };
  }, [urlSearch]);

  const [isHardwareRefetchLoading, setIsHardwareRefetchLoading] = useState<boolean>(false);
  const [isGroupRefetchLoading, setIsGroupRefetchLoading] = useState<boolean>(false);
  const {
    data: { hardwares = [], total = 0 } = {},
    isLoading,
    refetch: hardwareRefetchData,
  } = useQuery(['hardwares', 'list', hardwareQueryParams], () => getHardwaresList(hardwareQueryParams), {
    select({ data: { hardwares, total } }) {
      return { hardwares, total };
    },
  });

  const groupQueryParams = useMemo(() => {
    const page = urlSearch.get('page') ?? 1;
    const limit = urlSearch.get('limit') ?? PAGE_SIZE;
    const orderType = urlSearch.get('orderType') ?? 'desc';
    const orderBy = urlSearch.get('orderBy') ?? 'createdAt';
    const search = urlSearch.get('search');
    const hardwareType = urlSearch.get('filter[hardwareType]')?.split(',') ?? [];
    const viewMode = urlSearch.get('viewMode');

    return {
      page,
      limit,
      orderType,
      orderBy,
      viewMode,
      search,
      filter: { hardwareType },
    } as HardwareGroupSearchParamsI & { viewMode?: string };
  }, [urlSearch]);

  const {
    data: { hardwareGroups = [], total: groupTotal = 0 } = {},
    isLoading: isGroupLoading,
    refetch: groupRefetchData,
  } = useQuery(['hardware-groups', 'list', groupQueryParams], () => getHardwareGroupsList(groupQueryParams), {
    select({ data: { hardwareGroups, total } }) {
      return { hardwareGroups, total };
    },
  });

  const tabItems: TabsProps['items'] = [
    ...(trackingAccess?.isCanRead
      ? [
          createTabItem(
            '1',
            `All Hardware (${total ?? 0})`,
            <TrackingAllHardwareTab
              queryParams={hardwareQueryParams}
              hardwares={hardwares}
              total={total}
              isLoading={isLoading}
              refetchData={hardwareRefetchData}
              isRefetchLoading={isHardwareRefetchLoading}
              setIsRefetchLoading={setIsHardwareRefetchLoading}
            />,
          ),
        ]
      : []),
    ...(groupAccess?.isCanRead
      ? [
          createTabItem(
            '2',
            `Hardware Group (${groupTotal ?? 0})`,
            <TrackingGroupTab
              queryParams={groupQueryParams}
              hardwareGroups={hardwareGroups}
              total={groupTotal}
              isLoading={isGroupLoading}
              refetchData={groupRefetchData}
              isRefetchLoading={isGroupRefetchLoading}
              setIsRefetchLoading={setIsGroupRefetchLoading}
            />,
          ),
        ]
      : []),
  ];

  const createAllocationItems: MenuProps['items'] = [
    ...(groupAccess?.isCanCreate
      ? [
          {
            label: (
              <Link to="/hardware-groups/new">
                <Icon name="icon-group_work" /> <span>Create Group</span>
              </Link>
            ),
            key: '0',
          },
        ]
      : []),
    ...(depleteAccess?.isCanCreate
      ? [
          {
            label: (
              <Link to="/hardware-depletions/new">
                <Icon name="icon-local_drink" /> <span>Mark ecoTOTEs as Depleted</span>
              </Link>
            ),
            key: '1',
          },
        ]
      : []),
  ];

  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4">
      <div className="tw-flex-row tw-flex tw-items-center tw-justify-between tw-gap-4">
        <Text component="h3" variant="h3">
          Tracking
        </Text>
        <Dropdown
          menu={{ items: createAllocationItems }}
          trigger={['click']}
          overlayClassName="create-allocation-dropdown"
        >
          <Space>
            <PrimaryButton label="Add Action" icon={<PlusIcon />} />
          </Space>
        </Dropdown>
      </div>
      <Tabs defaultActiveKey="1" items={tabItems} rootClassName="app-tabs" className="tw-mt-2" />
    </div>
  );
};

export default TrackingPage;
