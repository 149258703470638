import { Checkbox, Form } from 'antd';
import React from 'react';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import Text from '../Text';

type FormParams = {
  status: string;
};

type Props = {
  handleFilter: (status: string) => void;
};

const ProductTraceLotNumbersFilterContent: React.FC<Props> = ({ handleFilter }) => {
  const [form] = Form.useForm();

  const handleOnFinish = (values: FormParams) => {
    handleFilter(values?.status);
  };

  const handleResetFilter = () => {
    form.resetFields();
    handleFilter('');
  };

  return (
    <div className="partner-filter-content">
      <Text variant="h3">Filter</Text>
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        layout="vertical"
      >
        <div className="form-container tw-flex tw-flex-col tw-gap-3 tw-justify-between">
          <div className="form-content">
            <Form.Item name="status" valuePropName="checked">
              <Checkbox>Selected</Checkbox>
            </Form.Item>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-3">
            <PrimaryButton label="Apply Filter" htmlType="submit" />
            <SecondaryButton label="Reset Filter" onClick={handleResetFilter} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ProductTraceLotNumbersFilterContent;
