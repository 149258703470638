import { Checkbox, Form } from 'antd';
import React, { useMemo } from 'react';
import ActionSection from './ActionSection';
import { ModuleI } from '../../interfaces/module';
import { RoleI } from '../../interfaces/role';
import { CheckboxOptionI } from '../../interfaces';
import { handleGetPermissionFromModule } from '../../utils/role';

type Props = {
  modules?: ModuleI[];
  role?: RoleI;
};

const RoleProductCollapseDetail: React.FC<Props> = ({ modules, role }) => {
  const productCheckboxOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Product'),
    [modules],
  );

  const productGroupOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'Product_grouping'),
    [modules],
  );

  const productTraceOptions: CheckboxOptionI[] = useMemo(
    () => handleGetPermissionFromModule(modules, 'ProductTrace'),
    [modules],
  );

  return (
    <>
      <Form.Item name="colProduct">
        <ActionSection
          label="Product"
          content={<Checkbox.Group options={productCheckboxOptions} value={role?.colProduct} />}
        />
      </Form.Item>
      <Form.Item name="colProduct_grouping">
        <ActionSection
          label="Product Grouping"
          content={<Checkbox.Group options={productGroupOptions} value={role?.colProduct_grouping} />}
        />
      </Form.Item>
      <Form.Item name="colProductTrace">
        <ActionSection
          label="Product Trace"
          content={<Checkbox.Group options={productTraceOptions} value={role?.colProductTrace} />}
        />
      </Form.Item>
    </>
  );
};

export default RoleProductCollapseDetail;
