import { Checkbox, Form } from 'antd';
import React, { useEffect, useMemo } from 'react';
import PrimaryButton from '../../buttons/PrimaryButton';
import Text from '../../Text';
import SecondaryButton from '../../buttons/SecondaryButton';
import { useQuery } from 'react-query';
import SelectAllCheckbox from '../../SelectAllCheckbox';
import { ProductI, ProductSearchParamsI } from '../../../interfaces/product';
import { ProductTraceReportTypeT, ProductTraceSearchParamsI } from '../../../interfaces/productTrace';
import { getProductsList } from '../../../apis/products';
import ProductTraceStatus from '../../ProductTrace/Status';

type FormParams = {
  status: string[];
  productIds: string[];
};

const productQueryParams: ProductSearchParamsI = {
  orderBy: 'name',
  orderType: 'desc',
};

type Props = {
  queryParams: ProductTraceSearchParamsI;
  onChange(newParams: Partial<ProductTraceSearchParamsI>): void;
};

const ProductTraceFilterContent: React.FC<Props> = ({ queryParams, onChange }) => {
  const [form] = Form.useForm();

  const handleResetFilter = () => {
    form.resetFields();
    onChange({ filter: {} });
  };

  const handleOnFinish = (values: FormParams) => {
    onChange({
      filter: { status: values?.status, productId: values?.productIds },
    });
  };

  const { data: { products = [] } = {} } = useQuery(['products', 'list', productQueryParams], () =>
    getProductsList(productQueryParams),
  );

  const statusOptions = ['onHold', 'productRecall', 'resolved', 'trace'] as ProductTraceReportTypeT[];
  const productOptions = useMemo(() => {
    return products
      .map((item: ProductI) => {
        return { label: item.name, value: `${item.id}` };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [products]);

  useEffect(() => {
    form.setFieldsValue({
      status: queryParams.filter?.status,
      siteIds: queryParams.filter?.productId,
    });
  }, [queryParams]);

  return (
    <div className="partner-filter-content">
      <Text variant="h3">Filter</Text>
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        onFinish={handleOnFinish}
        scrollToFirstError
        layout="vertical"
        className="tw-max-h-[580px]"
      >
        <div className="form-container tw-flex tw-flex-col tw-gap-3 tw-justify-between">
          <div className="form-content tw-max-h-[450px] tw-overflow-auto tw-pr-4">
            <div className="status-section">
              <Form.Item name="status">
                <SelectAllCheckbox form={form} plainOptions={statusOptions} name="status" sectionTitle="Status">
                  {statusOptions.map((status) => (
                    <Checkbox key={status} value={status}>
                      <ProductTraceStatus status={status} />
                    </Checkbox>
                  ))}
                </SelectAllCheckbox>
              </Form.Item>
              <Form.Item name="productId">
                <SelectAllCheckbox form={form} plainOptions={productOptions} name="productId" sectionTitle="Product">
                  {productOptions.map((item: { label: string; value: string }) => (
                    <Checkbox key={item.value} value={item.value}>
                      {item.label}
                    </Checkbox>
                  ))}
                </SelectAllCheckbox>
              </Form.Item>
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-3 tw-pb-4">
            <PrimaryButton label="Apply Filter" htmlType="submit" />
            <SecondaryButton label="Reset Filter" onClick={handleResetFilter} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ProductTraceFilterContent;
