import { Modal, Tabs, TabsProps } from 'antd';
import React, { Fragment, useState } from 'react';
import QRCodeIcon from '../../svgs/QRCodeIcon';
import KeyboardIcon from '../../svgs/KeyboardIcon';
import SecondaryButton from '../buttons/SecondaryButton';
import PrimaryButton from '../buttons/PrimaryButton';
import Text from '../Text';
import { Notice } from '../Notice';
import WarningIcon from '../../svgs/WarningIcon';
import FillingScanQRCode from '../HardwareFilling/ScanQRCode';
import FillingManual from '../HardwareFilling/Manual';
import Icon from '../Icon';
import ErrorModalIcon from '../../svgs/ErrorModalIcon';
import ProductIllustration from '../../svgs/illustrations/ProductIllustration';
import { getHardwareProductBySKU } from '../../apis/hardwares';
import { ProductI } from '../../interfaces/product';

const ScanNoticeMessage = () => {
  return (
    <>
      <Text variant="inputLabelSm">
        QR/Barcode for Product can be found on the Brand Plate near the handle of ecoTOTE. <br />
        Please note that it’s not to be mistaken with the QR/Barcode of the ecoTOTE itself on the outer casing.
      </Text>
    </>
  );
};

const warningDescription =
  'Ensure that the Product is owned by your partnership and has been scanned in a Fill process before.';

type Props = {
  productScanned?: ProductI;
  stopDecodingScanner: boolean;
  pageName?: string;
  setCurrentStep: (_: number) => void;
  setProductScanned: (_: ProductI | undefined) => void;
  setStopDecodingScanner: (_: any) => void;
};

const ProductTraceScanProductStep: React.FC<Props> = ({
  stopDecodingScanner,
  pageName,
  productScanned,
  setCurrentStep,
  setProductScanned,
  setStopDecodingScanner,
}) => {
  const [screen, setScreen] = useState<'start' | 'scan' | 'scanned'>('start');
  const [modal, contextHolder] = Modal.useModal();

  const handleScanOrManualSubmit = async (values: { text: string }) => {
    let status = 'success';
    try {
      const result = await getHardwareProductBySKU(values.text);
      setTimeout(() => {
        if (status === 'success') {
          setScreen('scanned');
          setProductScanned(result);
        }
      }, 1000);
    } catch (error: any) {
      const errorModal = modal.error({
        type: 'error',
        title: 'Invalid Product Code!',
        content: 'The scanned product code does not belong to  your partnership. Please try again!',
        className: 'context-modal-custom-container',
        closable: true,
        centered: true,
        footer: null,
        icon: <ErrorModalIcon color="#B13939" />,
        width: 345,
      });

      setTimeout(() => {
        errorModal.destroy();
      }, 1000);
    }
  };

  const items: TabsProps['items'] = [
    {
      key: 'scanner',
      label: (
        <Fragment>
          <QRCodeIcon /> <span>QR/Bar Code</span>
        </Fragment>
      ),
      children: (
        <FillingScanQRCode
          handleScanOrManualSubmit={handleScanOrManualSubmit}
          scanNoticeMessage={<ScanNoticeMessage />}
          stopDecodingScanner={stopDecodingScanner}
          setStopDecodingScanner={setStopDecodingScanner}
          pageName={pageName}
          title="Scan Product Code"
        />
      ),
    },
    {
      key: 'manual',
      label: (
        <Fragment>
          <KeyboardIcon /> <span>Manual</span>
        </Fragment>
      ),
      children: (
        <FillingManual
          label="Product Code"
          scanNoticeMessage={<ScanNoticeMessage />}
          handleScanOrManualSubmit={handleScanOrManualSubmit}
        />
      ),
    },
  ];

  const onDiscard = () => {
    if (productScanned) {
      setScreen('scan');
    } else {
      setScreen('start');
    }
    setProductScanned(undefined);
  };

  const onContinue = () => {
    setCurrentStep(2);
  };

  const onTabsChange = (value: string) => {
    setTimeout(
      () =>
        setStopDecodingScanner(() => {
          if (value === 'scanner') {
            return false;
          } else {
            return true;
          }
        }),
      2000,
    );
  };

  const onStartScreenClick = () => {
    setStopDecodingScanner(false);
    setScreen('scan');
  };

  return (
    <>
      <div className="tw-w-[672px] tw-p-3 tw-mb-[70px] tw-my-0 tw-mx-auto sm:tw-w-full">
        {screen === 'start' && (
          <>
            <Text variant="h3" component="h3" className="tw-mb-6 tw-w-full tw-text-center">
              Scan Product Code
            </Text>
            <div className="tw-max-w-[450px] tw-max-h-[300px] tw-my-0 tw-mx-auto tw-bg-white tw-border tw-border-solid tw-border-grey-300 tw-rounded-lg tw-pl-3">
              <div className="tw-text-center tw-py-4">
                <ProductIllustration />
              </div>
            </div>
            <div className="tw-max-w-[450px] tw-my-0 tw-mx-auto">
              <ul className="tw-my-6">
                <li>
                  QR/Barcode for Product can be found on the Brand Plate near the handle of ecoTOTE, or your Product
                  Management’s database
                </li>
              </ul>
              {warningDescription && (
                <Notice description={warningDescription} type="warning" icon={<WarningIcon />} closable={false} />
              )}
            </div>
          </>
        )}
        {screen === 'scan' && (
          <Tabs defaultActiveKey="scanner" items={items} onChange={onTabsChange} className="scan-workflow-tab" />
        )}
        {screen === 'scanned' && (
          <div className="">
            <p className="label tw-block tw-mb-6 tw-text-center">1 Product Scanned:</p>
            <div className="tw-w-[176px] tw-my-4 tw-mx-auto tw-bg-white tw-py-4 tw-px-3 tw-rounded-lg tw-shadow-simple">
              <Text variant="capt1" className="brand tw-flex tw-flex-row tw-items-center tw-gap-4">
                <span>{productScanned?.brandName}</span>
              </Text>
              <Text variant="bodyLgB" className="tw-mt-1 tw-text-primary-900 tw-mb-3">
                {productScanned?.name}
              </Text>
              <div className="tw-flex tw-flex-col tw-gap-3">
                <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
                  <Icon name="icon-person" className="tw-text-grey-700 tw-text-xs tw-min-w-4" size={16} />
                  <Text variant="inputLabelSm" className="flex-1">
                    {productScanned?.partner?.legalName}
                  </Text>
                </div>
                <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
                  <Icon name="icon-invert_colors" className="tw-text-grey-700 tw-text-xs tw-min-w-4" size={16} />
                  <Text variant="inputLabelSm" className="flex-1">
                    {`${productScanned?.abv ?? 0}% (ABV)`}
                  </Text>
                </div>
                <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
                  <Icon name="icon-receipt" className="tw-text-grey-700 tw-text-xs tw-min-w-4" size={16} />
                  <Text variant="inputLabelSm" className="flex-1">
                    {productScanned?.sku}
                  </Text>
                </div>
                <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-mb-3">
                  <Icon name="icon-wine_bar" className="tw-text-grey-700 tw-text-xs tw-min-w-4" size={16} />
                  <Text variant="inputLabelSm" className="flex-1">
                    {productScanned?.category.name}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {contextHolder}
      <div className="hardware-footer-section">
        <div>
          {screen === 'start' && <PrimaryButton label={'Scan Product Code'} onClick={onStartScreenClick} />}
          {screen !== 'start' && (
            <>
              <SecondaryButton
                label={productScanned ? 'Rescan' : 'Previous Step'}
                onClick={onDiscard}
                className={
                  !productScanned
                    ? '!tw-text-grey-900 hover:!tw-text-grey-900 !tw-border-primary hover:!tw-border-primary'
                    : ''
                }
              />
              <PrimaryButton label={'Continue'} onClick={onContinue} disabled={!productScanned} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductTraceScanProductStep;
