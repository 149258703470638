import { Form, Input } from 'antd';
import React, { ReactNode, useState } from 'react';
import PrimaryButton from '../buttons/PrimaryButton';
import Text from '../Text';
import Icon from '../Icon';

type Props = {
  label?: string;
  scanNoticeMessage: string | ReactNode;
  handleScanOrManualSubmit: (_: { text: string }) => void;
};

const errorMessage =
  'Please ensure your input only includes letters, numbers, and the special characters -, _, (, ), and /. Spaces and other special characters are not allowed.';

const FillingManual: React.FC<Props> = ({ label, scanNoticeMessage, handleScanOrManualSubmit }) => {
  const [form] = Form.useForm();
  const [warningMessage, setWarningMessage] = useState('');

  const onFinish = (value: { text: string }) => {
    handleScanOrManualSubmit(value);
  };

  const handleValidation = (_: any, value: any) => {
    if (!value) {
      return Promise.resolve();
    }

    if (value.includes(' ')) {
      setWarningMessage(errorMessage);
      return Promise.reject(new Error('Blank spaces are not allowed'));
    }

    return Promise.resolve();
  };

  const handleInputChange = (e: any) => {
    const { value } = e.target;
    const disallowedChars = /[^a-zA-Z0-9-_()/]/g;

    if (disallowedChars.test(value)) {
      const cleanedValue = value.replace(disallowedChars, '');
      form.setFieldsValue({
        text: cleanedValue,
      });
      if (!value.includes(' ')) {
        setWarningMessage(errorMessage);
      }
    } else {
      form.setFieldsValue({
        text: value,
      });
      if (value === '') {
        setWarningMessage('This field is required');
      } else {
        setWarningMessage('');
      }
    }
  };

  return (
    <div className="component-hardware-filling-manual-step">
      <Text variant="h2" className="tw-w-full tw-text-center">
        Manual Input
      </Text>
      <p className="tw-flex tw-justify-center tw-gap-3 tw-max-w-[500px] tw-my-4 tw-mx-auto">
        <Icon name="icon-info" className="tw-min-w-4 tw-w-4" />
        <span>{scanNoticeMessage}</span>
      </p>
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        scrollToFirstError
        onFinish={onFinish}
        layout="vertical"
        className="tw-bg-white tw-p-4 tw-rounded-lg tw-shadow-md"
      >
        <div className="form-container">
          <div className="form-content !tw-mb-[46px]">
            <Form.Item
              label={`Enter ${label ?? 'Barcode Number'}`}
              name="text"
              rules={[{ required: true, message: 'This field is required' }, { validator: handleValidation }]}
              help={warningMessage || ''}
              validateStatus={warningMessage ? 'error' : undefined}
            >
              <Input placeholder={`Type ${label ?? 'Barcode'}`} onChange={handleInputChange} />
            </Form.Item>
          </div>
        </div>
        <PrimaryButton label="Scan" htmlType="submit" className="tw-w-full" />
      </Form>
    </div>
  );
};

export default FillingManual;
