import React from 'react';
import { ProductTraceReportTypeT } from '../../interfaces/productTrace';
import Icon from '../Icon';
import { Tooltip } from 'antd';

type Props = {
  status: ProductTraceReportTypeT;
  iconSize?: number;
  buttonSize?: number;
};

const ProductTraceStatusIcon: React.FC<Props> = ({ status, iconSize = 16, buttonSize = 20 }) => {
  return (
    <Tooltip
      placement="bottom"
      title={status === 'onHold' ? 'On Hold' : 'Product Recall'}
      arrow={true}
      className={`tw-max-w-[210px]`}
    >
      <span
        className="tw-inline-flex tw-items-center tw-justify-center tw-border-primary tw-rounded tw-bg-grey-200"
        style={{ width: `${buttonSize}px`, height: `${buttonSize}px` }}
      >
        {status === 'onHold' && (
          <Icon name="icon-recall_onhold_filled" size={iconSize} className="tw-text-warning-300" />
        )}
        {status === 'productRecall' && <Icon name="icon-recall_filled" size={iconSize} className="tw-text-error-500" />}
      </span>
    </Tooltip>
  );
};

export default ProductTraceStatusIcon;
