import React from 'react';
import { CommonStatus } from '../CommonStatus';
import DangerButton from '../buttons/DangerButton';
import { HardwareItemI } from '../../interfaces/hardware';
import { getTypeFromApi, handleDisplayIconByTypeFromApi } from '../../utils/hardware';
import TrashIcon from '../../svgs/TrashIcon';
import { RegistrationHistoryI } from '../../interfaces/registrationHistory';
import Text from '../Text';
import Icon from '../Icon';
import Divider from '../Divider';
import { SiteI } from '../../interfaces/site';
import TooltipIcon from '../buttons/TooltipIcon';

type Props = {
  item: HardwareItemI;
  data: RegistrationHistoryI;
  canDelete?: boolean;
  site?: SiteI;
  handleDeleteItem?: (_: HardwareItemI) => void;
};

const HardwareRegisterItemCard: React.FC<Props> = ({ item, data, canDelete = true, site, handleDeleteItem }) => {
  const onDeleteClick = () => {
    handleDeleteItem?.(item);
  };

  return (
    <div className="tw-bg-white tw-rounded-lg tw-shadow-simple tw-flex tw-flex-col">
      <div className="tw-flex tw-flex-col tw-justify-between tw-h-full tw-py-4 tw-px-4">
        <div>
          <div className="tw-flex tw-justify-between tw-items-center">
            <Text variant="capt1" className=" tw-flex tw-items-center tw-gap-0.5">
              {handleDisplayIconByTypeFromApi(item.type, { color: '#191919', width: 16, height: 16 })}
              <span>{getTypeFromApi(item)}</span>
            </Text>
            {item.isManualInput && !item.error && (
              <TooltipIcon
                icon="icon-keyboard"
                text="Manual Input"
                placement="top"
                iconClassName={'tw-text-grey-900'}
              />
            )}
          </div>
          <Text variant="inputLabelLg" className="tw-mt-1 tw-text-primary-900">{`${item.serialCode}`}</Text>
          <Text variant="inputLabelSm" className="tw-flex tw-items-center tw-gap-0.5 tw-mb-3">
            <>
              <Icon name="icon-location_on" size={12} className="tw-text-grey-700" />
              {site ? (
                <span>{site?.legalName}</span>
              ) : (
                <span>{!data.site ? 'Unassigned Site' : data?.site?.legalName}</span>
              )}
            </>
          </Text>
        </div>
        {item?.error ? (
          <Text>
            <CommonStatus status={'inactive'} text={item.error} />
          </Text>
        ) : (
          <span className="tw-flex tw-items-center tw-gap-0.5">
            {item?.status && <CommonStatus status={item?.status} />}
            {item?.condition && <CommonStatus status={item?.condition} />}
          </span>
        )}
      </div>
      <Divider />
      <div className="tw-flex tw-justify-between tw-py-2 tw-px-3 tw-min-h-[26px]">
        {!item.error ? (
          <a href={''} className="tw-flex tw-items-center tw-gap-1">
            <Icon name="icon-flag" size={12} className="tw-text-primary-800" />
            <Text variant="buttonSm">Report</Text>
          </a>
        ) : (
          <span className="tw-leading-5 tw-mt-3"></span>
        )}
        {canDelete && <DangerButton icon={<TrashIcon width={12} height={12} />} onOk={onDeleteClick} />}
      </div>
    </div>
  );
};

export default HardwareRegisterItemCard;
