import { Button, Tooltip } from 'antd';
import React from 'react';
import Icon from '../Icon';
import { IconName } from '../Icon/iconName';

type Props = {
  text: string;
  className?: string;
  iconSize?: number;
  buttonClassName?: string;
  iconClassName?: string;
  icon?: IconName;
  placement?: 'top' | 'bottom' | 'left' | 'right';
};

const TooltipIcon: React.FC<Props> = ({
  text,
  icon = 'icon-info',
  className,
  iconSize = 16,
  buttonClassName,
  placement = 'bottom',
  iconClassName = '',
}) => {
  return (
    <Tooltip
      placement={placement}
      title={<span dangerouslySetInnerHTML={{ __html: text }} />}
      arrow={true}
      className={`tw-max-w-[210px] ${className}`}
    >
      <Button
        className={`tw-p-0 tw-border-none tw-shadow-none tw-w-4 !tw-h-4 tw-text-info-500 hover:!tw-text-info-500 ${buttonClassName}`}
      >
        <Icon name={icon} size={iconSize} className={iconClassName} />
      </Button>
    </Tooltip>
  );
};

export default TooltipIcon;
