import React, { ReactNode } from 'react';
import Text from '../Text';

type Props = {
  title: string;
  icon?: ReactNode;
  valueText: ReactNode;
  itemTotalValue: number;
  maxValue: number;
  itemValue: number;
  isBackground?: boolean;
  labelGap?: number;
  barColor?: string;
  backgroundColor?: string;
};

const HorizonBarItem: React.FC<Props> = ({
  title,
  icon,
  itemValue,
  itemTotalValue,
  maxValue,
  valueText,
  isBackground = true,
  labelGap = 127,
  barColor,
  backgroundColor = '#e3e3e3',
}) => {
  return (
    <div className="tw-flex tw-items-center tw-mb-4 last:tw-mb-0">
      <div
        className={`tw-flex tw-items-center tw-gap-2 sm:tw-gap-1`}
        style={{ minWidth: labelGap + 'px', maxWidth: labelGap + 'px' }}
      >
        {icon ?? ''}
        <Text variant="labelMd" className="">
          {title}
        </Text>
      </div>
      <div className="tw-w-full tw-mr-2">
        <span
          className={`tw-block tw-h-5 tw-relative`}
          style={{
            width: `${itemTotalValue === maxValue ? '100%' : `${itemTotalValue}%`}`,
            background: isBackground ? backgroundColor : undefined,
          }}
        >
          <span
            className={`tw-absolute tw-top-0 tw-left-0 tw-h-full`}
            style={{ width: `${itemValue}%`, background: barColor ?? '#94c482' }}
          />
        </span>
      </div>
      <div className="tw-h-6 tw-min-w-[70px] tw-text-right">
        <Text variant="labelMd" className="">
          {valueText}
        </Text>
      </div>
    </div>
  );
};

export default HorizonBarItem;
