import React from 'react';
import { Tabs, TabsProps } from 'antd';
import PaperClipIcon from '../../svgs/PaperClipIcon';
import Attachments from '../Attachments';
import { ProductTracePartners } from './Partners';
import { ProductTraceI } from '../../interfaces/productTrace';
import Icon from '../Icon';

type Props = {
  data?: ProductTraceI;
};

const ProductTraceResources: React.FC<Props> = ({ data }) => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span className="tab-title">
          <Icon name="icon-supervised_user_circle_outline" size={20} /> <span>Partners</span>
        </span>
      ),
      children: (
        <ProductTracePartners
          data={data}
          partnersAffected={data?.partners?.length}
          ecoTOTEsAffected={data?.ecoToteIds?.length}
        />
      ),
    },
    {
      key: '2',
      label: (
        <span className="tab-title">
          <PaperClipIcon /> <span>Attachment</span>
        </span>
      ),
      children: <Attachments attachments={data?.attachments} />,
    },
  ];

  return (
    <div className="filling-hardware-attachment-container details-container tw-mt-6" style={{ marginBottom: 16 }}>
      <div className="content-wrap">
        <Tabs defaultActiveKey="1" items={items} rootClassName="hardware-attachment-tabs" />
      </div>
    </div>
  );
};

export default ProductTraceResources;
