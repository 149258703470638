import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { ProductTraceI } from '../../interfaces/productTrace';
import Icon from '../Icon';
import { TableColumnsType } from 'antd';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource: ProductTraceI['partners'];
  reportType?: ProductTraceI['status'];
};

const ProductTrackAffectedPartnersTable: React.FC<Props> = ({ dataSource, reportType, withAction, ...props }) => {
  const getColumns = () => {
    const columns: TableColumnsType = [
      {
        title: 'Partner Name',
        width: 150,
        ellipsis: true,
        dataIndex: 'displayName',
        render: (displayName: string) => displayName,
      },
      {
        title: 'Lot number',
        width: 150,
        ellipsis: true,
        dataIndex: 'lotNumbers',
        render: (lotNumbers: string[]) => lotNumbers?.join(', '),
      },
      {
        title: 'affected ecototes',
        width: 180,
        ellipsis: true,
        dataIndex: 'ecoToteIds',
        render: (ecoToteIds: number[]) => ecoToteIds?.length,
      },
      {
        title: 'full ecototes',
        width: 140,
        ellipsis: true,
        dataIndex: 'fullEcoTotes',
      },
      {
        title: 'partial ecototes',
        width: 180,
        ellipsis: true,
        dataIndex: 'partialEcoTotes',
      },
      {
        title: 'Total vol. introduced (L)',
        width: 150,
        dataIndex: 'totalVolIntroduced',
        render: (totalVolIntroduced: number) => (totalVolIntroduced / 1000).toFixed(2),
      },
      {
        title: 'vol. in circulation (L)',
        width: 150,
        dataIndex: 'volInCirculation',
        render: (volInCirculation: number) => (volInCirculation / 1000).toFixed(2),
      },
    ];

    if (reportType !== 'trace') {
      columns.push({
        title: 'email notify',
        width: 100,
        dataIndex: 'isEmailNotify',
        render: (isEmailNotify: boolean) => {
          return (
            <Icon
              name={isEmailNotify ? 'icon-mark_email_read' : 'icon-mark_email_unread'}
              size={20}
              className={isEmailNotify ? 'tw-text-success-500' : 'tw-text-error-500'}
            />
          );
        },
      });
    }

    return columns;
  };

  const COLUMNS = getColumns();

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default ProductTrackAffectedPartnersTable;
