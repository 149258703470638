import { Spin } from 'antd';
import React from 'react';

const ProductTraceUpdate: React.FC = () => {
  return (
    <Spin>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-p-4">Update</div>
    </Spin>
  );
};

export default ProductTraceUpdate;
