import React from 'react';
import TableBase, { Props as TableProps } from './Base';
import { DateFormat } from '../../utils/global';
import { ProductTraceI, ProductTraceReportTypeT } from '../../interfaces/productTrace';
import ProductTraceStatus from '../ProductTrace/Status';

type Props = TableProps & {
  withAction?(_: any): void;
  dataSource?: ProductTraceI[];
};

const ProductTracesTable: React.FC<Props> = ({ dataSource, withAction, ...props }) => {
  const COLUMNS = [
    {
      title: 'Report',
      width: 200,
      ellipsis: true,
      dataIndex: 'code',
    },
    {
      title: 'no. of hardware',
      dataIndex: 'ecoToteIds',
      width: 200,
      ellipsis: true,
      render: (ecoToteIds: number[]) => {
        return <span>{ecoToteIds?.length}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 120,
      ellipsis: true,
      render: (status: ProductTraceReportTypeT) => {
        return <ProductTraceStatus status={status} />;
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      width: 180,
      ellipsis: true,
      render: (createdAt: string) => {
        return <span>{DateFormat(createdAt)}</span>;
      },
    },
  ];

  if (withAction) {
    COLUMNS.push({
      key: 'action',
      width: 50,
      fixed: 'right' as unknown as boolean,
      render(record: any) {
        return withAction(record);
      },
    } as any);
  }
  return <TableBase dataSource={dataSource} columns={COLUMNS} pagination={false} scroll={{ x: '100%' }} {...props} />;
};

export default ProductTracesTable;
