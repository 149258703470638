import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { HardwareItemI } from '../../../interfaces/hardware';
import Text from '../../Text';
import { getTypeFromApi, handleDisplayIconByTypeFromApi } from '../../../utils/hardware';
import TooltipIcon from '../../buttons/TooltipIcon';

type Props = {
  item: HardwareItemI;
};
const HardwareDetialsCardItem: React.FC<Props> = ({ item }) => {
  const data: HardwareItemI = useMemo(() => {
    if (item?.currentData) return { ...item.currentData, isManualInput: item.isManualInput };
    return { ...item, isManualInput: item.isManualInput };
  }, [item]);
  const navitate = useNavigate();

  const handleCardClick = () => {
    navitate(`/tracking/${data?.serialCode}`);
  };

  return (
    <>
      {!data.error && (
        <button
          className="tw-border-none tw-shadow-simple tw-bg-white tw-rounded-lg tw-p-0 tw-flex tw-justify-between tw-flex-col tw-cursor-pointer tw-min-w-[176px]"
          onClick={handleCardClick}
        >
          <div className="tw-py-4 tw-px-3 tw-h-full tw-w-full">
            <>
              <div className="tw-flex tw-justify-between tw-items-center tw-w-full tw-mb-1">
                <Text variant="inputLabelSm" className="tw-flex tw-items-center tw-gap-1">
                  {handleDisplayIconByTypeFromApi(data?.type, { color: '#191919', width: 16, height: 16 })}
                  <span>{getTypeFromApi(data?.currentData ?? data)}</span>
                </Text>
                {data.isManualInput && (
                  <TooltipIcon
                    icon="icon-keyboard"
                    text="Manual Input"
                    placement="top"
                    iconClassName={'tw-text-grey-900'}
                  />
                )}
              </div>
              <Text
                variant="inputLabelLg"
                className="tw-mt-1 tw-text-primary-900 !tw-block tw-text-left"
              >{`${data?.serialCode}`}</Text>
            </>
          </div>
        </button>
      )}
    </>
  );
};

export default HardwareDetialsCardItem;
